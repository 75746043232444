import React from 'react';
import ReactDOM from 'react-dom';
import { Linkees } from './components/Linkees';
import './css/normalize.css';
import { CHANNEL_TYPES } from './constants';

const items = [
  {
    title: 'Website',
    subtitle: 'danielliberty.com | More about me and what I do',
    type: CHANNEL_TYPES.WEBSITE,
    link: 'https://danielliberty.com',
  },
  {
    title: 'GitHub',
    subtitle: '@sudo-dliberty | My life in code',
    type: CHANNEL_TYPES.GITHUB,
    link: 'https://github.com/sudo-dliberty',
  },
  {
    title: 'Instagram',
    subtitle: '@d_libz |  My life in pictures and memes',
    type: CHANNEL_TYPES.INSTAGRAM,
    link: 'https://instagram.com/d_libz',
  },
  {
    title: 'LinkedIn',
    subtitle: 'My professional social life',
    type: CHANNEL_TYPES.LINKEDIN,
    link: 'https://www.linkedin.com/in/daniel-liberty-a4339689/',
  },
  {
    title: 'Facebook',
    subtitle: 'I do not really use this',
    type: CHANNEL_TYPES.FACEBOOK,
    link: 'https://www.facebook.com/danny.liberty',
  },

  {
    title: 'Threads',
    subtitle: '@d_libz | My life outside Twitter ',
    type: CHANNEL_TYPES.THREADS,
    link: 'https://www.threads.net/@d_libz',
  },
];

ReactDOM.render(
  <React.StrictMode>
    <Linkees cardItems={items} />
  </React.StrictMode>,
  document.getElementById('root')
);
