import website from '../assets/website.png';
import instagram from '../assets/instagram.png';
import linkedin from '../assets/linkedin.jpg';
import threads from '../assets/threads.jpeg';
import facebook from '../assets/facebook.jpeg';
import github from '../assets/github.png';

import { CHANNEL_TYPES } from '../constants';

export const CHANNEL_TYPE_VS_COVER_IMAGE = {
  [CHANNEL_TYPES.WEBSITE]: website,
  [CHANNEL_TYPES.INSTAGRAM]: instagram,
  [CHANNEL_TYPES.LINKEDIN]: linkedin,
  [CHANNEL_TYPES.THREADS]: threads,
  [CHANNEL_TYPES.FACEBOOK]: facebook,
  [CHANNEL_TYPES.GITHUB]: github,
};
